
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { ReactElement } from 'react';
import OrderOverviewScene from 'scenes/order-overview';
import { getLayout as getOrdersLayout } from 'components/layout/ssp-layout';
const OrderOverviewPage = (): ReactElement => <OrderOverviewScene />;
OrderOverviewPage.getLayout = getOrdersLayout;
export default OrderOverviewPage;

    async function __Next_Translate__getStaticProps__1936d004d14__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/order-overview/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1936d004d14__ as getStaticProps }
  